import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import {
  BuscarCep,
  MyCart,
  SendButtonInfo,
  StyledModalHeader,
  StyledRow,
} from './CartStyle';
import {
  Icon,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Input,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from '@polichat/flamboyant';

import CartImage from '../modals/src/CartImage';

import Avatar from '../../../../common/profile/Avatar';
import ContactName from '../../../../common/profile/ContactName';
import Animation from '../../../../common/animation';

import { priceMask, priceUnmask } from '../../../../../utils/priceMask';

import { usePoliPayGatewaysService } from '../cart/hooks/usePoliPayGateways';
import { CustomerChannelBalanceContext } from '../../contexts/customerChannelBalance/context';

import {
  canSendMessage,
  findChannelId,
  mountMessage,
  findSelectedExternalId,
  syncChatShoppingCartToServer,
} from '../../../../../store/modules/chat/functions';
import { toast } from 'react-toastify';
import BRStates from './util/BRStates';
import Countries from './util/Countries';

function CartPanel({
  chat,
  userData,
  updateCartAddress,
  updateAmountItemFromCart,
  removeItemFromCart,
  sendOrder,
  productsCatalog,
  current_customer_id,
  updateCartGateway,
  agentSettings,
}) {
  const contact = chat.contact;
  const user_id = userData?.id;
  const customer_id = userData?.customer_id
    ? userData.customer_id
    : current_customer_id;
  const openedChat = chat;
  const cartData = chat.cart;
  const cartProducts = chat.cart?.products;
  const cartAddress = chat.cart?.address;
  const cartCheckoutPath = chat.cart?.checkout_path;
  const agentName = agentSettings?.agent_name;

  //get balance context
  const { isUserAbleToSendMessage, loading: isLoadingCustomerChannelContext } =
    useContext(CustomerChannelBalanceContext);

  const { isLoadingGateways, PoliPayGateways, hasPoliPayGatewayActive } =
    usePoliPayGatewaysService();

  const [selectedGatewayId, setSelectedGatewayId] = useState();

  useEffect(() => {
    let gatewayDefault = PoliPayGateways.find(
      (gateway) => gateway.default === 1
    );
    if (cartData?.integration_id) {
      setSelectedGatewayId(cartData?.integration_id);
    } else if (gatewayDefault) {
      setSelectedGatewayId(gatewayDefault.integration_id);
    }
  }, [PoliPayGateways, openedChat]);

  //State to change input quantity
  const [productQuantity, setProductQuantity] = useState([]);

  const [modalAddAddress, setModalAddAddress] = useState(false);
  const toggleModalAddAddress = () => setModalAddAddress(!modalAddAddress);

  const [show, setShow] = useState(false);
  const toggle = () => setShow((prevState) => !prevState);
  const [myCep, setMyCep] = useState(
    !cartAddress
      ? {}
      : {
          country: cartAddress?.country,
          uf: cartAddress?.state,
          localidade: cartAddress?.city,
          bairro: cartAddress?.district,
          logradouro: cartAddress?.address,
          number: cartAddress?.number,
          complemento: cartAddress?.complement,
          cep: cartAddress?.cep,
        }
  );
  const [country, setCountry] = useState(cartAddress?.country || 'BR');
  const [state, setState] = useState(myCep?.uf ?? 'AC');
  const [city, setCity] = useState(myCep?.localidade || '');
  const [number, setNumber] = useState(cartAddress?.number || '');
  const [district, setDistrict] = useState(myCep?.bairro || '');
  const [address, setAddress] = useState(myCep?.logradouro || '');
  const [complement, setComplement] = useState(myCep?.complemento || '');
  const [cep, setCep] = useState(myCep?.cep || '');
  const [shipping_cost, setShippingCost] = useState(
    cartData?.shipping_cost || 0
  );

  const inputBusca = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const submitCEP = (evt) => {
    evt.preventDefault();
    async function fetchCep() {
      const response = await fetch(
        `https://viacep.com.br/ws/${inputBusca.current.value}/json/`
      );
      if (response.status === 404 || response.statusText === 'Not Found') {
        setError(true);
        setErrorMessage('O cep informado não foi encontrado.');
        setTimeout(function () {
          setError(false);
        }, 5000);
      } else {
        setError(false);
      }
      const json = await response.json();
      setMyCep(json);
      if (json.erro === true) {
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 5000);
        setErrorMessage('O cep informado não foi encontrado.');
      }
    }
    fetchCep();
  };
  const handleChangeShippingCost = useCallback((e) => {
    let dataPrice = e.currentTarget.value;
    dataPrice = priceUnmask(dataPrice);
    setShippingCost(dataPrice);
  }, []);

  useEffect(() => {
    setCountry(cartAddress?.country || 'BR');
    setState(myCep?.uf || '');
    setCity(myCep?.localidade || '');
    setDistrict(myCep?.bairro || '');
    setAddress(myCep?.logradouro || '');
    setNumber(cartAddress?.number || '');
    setComplement(myCep?.complemento || '');
    setCep(myCep?.cep || '');
    setShippingCost(cartData?.shipping_cost || 0);
  }, [myCep]);

  useEffect(() => {
    setMyCep(
      !cartAddress
        ? {}
        : {
            country: cartAddress?.country,
            uf: cartAddress?.state,
            localidade: cartAddress?.city,
            bairro: cartAddress?.district,
            logradouro: cartAddress?.address,
            number: cartAddress?.number,
            complemento: cartAddress?.complement,
            cep: cartAddress?.cep,
          }
    );
  }, [openedChat]);

  const addressData = {
    country,
    state,
    city,
    district,
    address,
    complement,
    cep,
    number,
  };
  const handleSubmitAddressAndShipping = (e) => {
    e.preventDefault();
    updateCartAddress(openedChat.id, addressData, shipping_cost);

    syncChatShoppingCartToServer(openedChat, openedChat.cart.id);

    toggleModalAddAddress();
  };

  const calcCartTotalValue = () => {
    let fullPrice = cartProducts?.reduce((acc, currentValue, index) => {
      currentValue = cartProducts[index].quantity * cartProducts[index].price;
      return acc + currentValue;
    }, 0);

    if (cartData?.shipping_cost)
      fullPrice =
        parseInt(fullPrice, 10) + parseInt(cartData?.shipping_cost, 10);

    return fullPrice;
  };

  const handleSendCartMessage = () => {
    if (canSendMessage(openedChat)) {
      let fullPrice = calcCartTotalValue();

      if (!cartProducts.length) return;

      if (!fullPrice) {
        toast.error(
          'Infelizmente não é possível o envio de pedidos com produtos de valores R$ 0,00'
        );
        return;
      }

      let text = `*${userData?.name} lhe enviou um pedido:*\nOlá ${
        contact.name
      }, o seu pedido *${cartData.id}* foi criado.\n${
        fullPrice ? `*Valor: ${priceMask(fullPrice + '')}*` : ''
      }\n*Clique no link para pagar*\n${cartCheckoutPath}`;

      if (agentName == 0) {
        text = `Olá ${
          contact.name
        }, o seu pedido *${cartData.id}* foi criado.\n${
          fullPrice ? `*Valor: ${priceMask(fullPrice + '')}*` : ''
        }\n*Clique no link para pagar*\n${cartCheckoutPath}`;
      }

      let message_body = {
        reference_type: 'order',
        reference_id: cartData.id,
        text,
        picture: `${process.env?.REACT_APP_CDN_HOST}/img/payment/carrinho_compras.png`,
        value: fullPrice,
        link: cartCheckoutPath,
      };

      let message_data = mountMessage(
        openedChat,
        JSON.stringify(message_body),
        'payment_link'
      );

      let data_send = {
        chat_id: openedChat.id,
        channel_id: findChannelId(openedChat),
        user_id,
        contact_id: contact?.id,
        external_id: findSelectedExternalId(openedChat),
        message_body,
        cuid: message_data.cuid,
        type: message_data.type,
      };

      sendOrder(
        openedChat.id,
        openedChat.cart.id,
        'order',
        {
          data_to_storage: message_data,
          data_to_send: data_send,
        },
        selectedGatewayId //send the integration_id on action sendOrder
      );

      //setShow(false);
    } else {
      toast.warning('Não foi possível enviar a mensagem.', {
        closeButton: false,
      });
    }
  };

  const handleChangeCep = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setCep(value);
  };

  const handleChangeGateway = (choiceGatewayId) => {
    setSelectedGatewayId(choiceGatewayId);
    updateCartGateway(chat.id, cartData.id, Number(choiceGatewayId));
    syncChatShoppingCartToServer(chat, cartData.id);
  };

  function incrementProduct(id, quantity) {
    quantity = parseInt(quantity);
    updateAmountItemFromCart(chat.id, id, quantity + 1);
    syncChatShoppingCartToServer(chat, cartData.id);
  }

  function decrementProduct(id, quantity) {
    quantity = parseInt(quantity);
    updateAmountItemFromCart(chat.id, id, quantity - 1);
    syncChatShoppingCartToServer(chat, cartData.id);
  }

  function removeProduct(id) {
    removeItemFromCart(chat.id, id);
    syncChatShoppingCartToServer(chat, cartData.id);
  }

  function saveProductQuantity(id, quantity) {
    quantity = parseInt(quantity);

    if (productsCatalog) {
      let currentProduct = productsCatalog.find((product) => product.id === id);

      if (currentProduct !== -1 && quantity > currentProduct.quantity) {
        toast.error(
          `Quantidade solicitada fora de estoque, ajustamos para a quantidade máxima de ${currentProduct.quantity} itens.`
        );
        quantity = currentProduct.quantity;
        if (document.getElementById('inputQuantityProducts')) {
          document.getElementById('inputQuantityProducts').value =
            currentProduct.quantity;
        }
      }
    }
    updateAmountItemFromCart(chat.id, id, quantity);
    syncChatShoppingCartToServer(chat, cartData.id);
  }

  if (hasPoliPayGatewayActive || isLoadingGateways) {
    return (
      <ButtonDropdown
        isOpen={show}
        toggle={toggle}
        color={'link'}
        className="cartPanel"
        direction="up"
      >
        <DropdownToggle
          color={'link'}
          border="border-none"
          id="tooltipCartChatPainel"
          caret
        >
          {!show ? (
            <>
              <UncontrolledTooltip
                placement="bottom"
                target="tooltipCartChatPainel"
              >
                Carrinho
              </UncontrolledTooltip>
              <Icon
                size={20}
                icon="poli-icon pi-ecommerce-fill"
                position={'relative'}
                top={'2px'}
                right={'4px'}
              />
            </>
          ) : (
            <>
              <Icon
                size={21}
                icon="poli-icon pi-ecommerce-fill"
                position={'relative'}
                top={'2px'}
                right={'4px'}
              />
            </>
          )}

          {!show && cartProducts && cartProducts.length > 0 ? (
            <span
              style={{
                position: 'absolute',
                top: '-13px',
                right: '-2px',
                background: 'var(--red-polichat)',
                borderRadius: '100%',
                fontSize: '0.6rem',
                lineHeight: '1rem',
                fontWeight: 'bold',
                color: 'var(--primary-background-color)',
                alignItems: 'center',
                textAlign: 'center',
                padding: '1px',
                height: '20px',
                width: '20px',
                display: 'inline',
                padding: '3px 0px 0px 0px',
              }}
              title={`${cartProducts.length} produtos no carrinho`}
            >
              {cartProducts.length}
            </span>
          ) : (
            ''
          )}
        </DropdownToggle>
        <DropdownMenu className="container__cart-modal">
          <MyCart>
            <div className="cartHeader">
              <div className="payment-chat-subsection-heading">
                <span className="payment-chat-title">Carrinho de:</span>
                <div className="contactInfo" style={{ padding: '0 0.5rem' }}>
                  <Avatar
                    className="contact"
                    title={contact.name}
                    url={contact.picture}
                    size={36}
                  />
                  <div className="contactNameContainer">
                    <ContactName name={contact.name} />
                  </div>
                </div>
              </div>
              <div className="payment-chat-subsection-heading payment-chat-preview">
                <div className="icon-wrapper">
                  <CartImage width={100} height={80} />
                </div>
              </div>
            </div>
            {isLoadingGateways || isLoadingCustomerChannelContext ? (
              <div>
                <Animation size="large" icon="chat-loading" />
              </div>
            ) : !isUserAbleToSendMessage ? (
              <div className="payment-chat-buttons-subsection">
                <p>
                  Seu acesso atingiu o limite de créditos contratados no
                  período, é necessário adicionar créditos extras ou
                  aguardar o próximo mês. Entre em contato com nossa área
                  comercial.
                </p>
              </div>
            ) : (
              <>
                <span className="payment-chat-title">Produtos:</span>

                <div className="cartProductsList">
                  {cartProducts && cartProducts.length > 0 ? (
                    cartProducts.map((product) => {
                      return (
                        <div className="cartProductItem" key={product.id}>
                          <span>{product.name}</span>
                          <div className="cartProductControls">
                            <Button
                              size={'small-icon'}
                              color="neutral"
                              onClick={() =>
                                incrementProduct(product.id, product.quantity)
                              }
                            >
                              <Icon
                                size={16}
                                position={'relative'}
                                top={'0px'}
                                left={'-4px'}
                                icon={'poli-icon pi-add-circle-line'}
                              />
                            </Button>
                            <CustomInput
                              type="text"
                              id="inputQuantityProducts"
                              value={product.quantity}
                              onChange={(e) => {
                                product.quantity = e.target.value;

                                if (e.target.value == '') {
                                  product.quantity = 0;
                                }
                                // Utilizado para poder manipular o value
                                setProductQuantity(product.quantity);
                              }}
                              onBlur={(e) => {
                                if (e.target.value == 0) {
                                  product.quantity = 1;
                                }
                                saveProductQuantity(
                                  product.id,
                                  product.quantity
                                );
                              }}
                            />
                            <Button
                              size={'small-icon'}
                              color="neutral"
                              onClick={() =>
                                decrementProduct(product.id, product.quantity)
                              }
                            >
                              <Icon
                                size={16}
                                position={'relative'}
                                top={'0px'}
                                left={'-4px'}
                                icon={'poli-icon pi-remove-circle-line'}
                              />
                            </Button>
                            <Button
                              size={'small-icon'}
                              color="neutral"
                              onClick={() => removeProduct(product.id)}
                            >
                              <Icon
                                size={20}
                                position={'relative'}
                                top={'0px'}
                                left={'-6px'}
                                icon={'poli-icon pi-delete-line'}
                                color={'var(--danger)'}
                              />
                            </Button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="cartProductNoItem">
                      <span>Não há produtos no carrinho.</span>
                    </div>
                  )}
                </div>

                <>
                  <div className="payment-chat-method">
                    <Label check className="payment-chat-label">
                      Método:
                    </Label>
                    <CustomInput
                      type="select"
                      id="payment_gateway"
                      name="payment_gateway"
                      value={selectedGatewayId}
                      onChange={(e) => handleChangeGateway(e.target.value)}
                    >
                      {PoliPayGateways.map((gateway) => (
                        <option key={gateway.id} value={gateway.integration_id}>
                          {gateway.name}
                        </option>
                      ))}
                    </CustomInput>
                  </div>
                  <div
                    className="payment-chat-subsection-heading"
                    style={{ marginTop: '1.3rem' }}
                  >
                    <span className="payment-chat-title">
                      ID do pedido #{cartData?.id}
                    </span>
                  </div>
                  <div className="payment-chat-buttons-subsection">
                    <Button
                      color="info"
                      width="total"
                      className="payment-chat-button"
                      onClick={() => setModalAddAddress(!modalAddAddress)}
                      disabled={!cartProducts || cartProducts.length === 0}
                    >
                      Dados de Entrega
                    </Button>

                    <Button
                      color="primary"
                      width="total"
                      className="payment-chat-button"
                      onClick={handleSendCartMessage}
                      disabled={
                        !cartProducts ||
                        !cartData ||
                        (cartData.needSyncToServer === true ? true : false) ||
                        cartProducts.length === 0 ||
                        !hasPoliPayGatewayActive
                      }
                    >
                      Enviar carrinho
                    </Button>
                  </div>
                </>
              </>
            )}
          </MyCart>
        </DropdownMenu>
        <Modal
          isOpen={modalAddAddress}
          toggle={toggleModalAddAddress}
          className="container__address--modal"
        >
          <StyledModalHeader
            toggle={toggleModalAddAddress}
            style={{ display: 'flex!important' }}
          >
            <Icon icon="poli-icon pi-truck-fill" color="#4FCAD2" size={50} />
            <span left="true">Adicionar entrega</span>
          </StyledModalHeader>
          <ModalBody style={{ overflowX: 'auto' }}>
            <Form onSubmit={submitCEP}>
              <StyledRow>
                <Col lg={7} md={7} sm={7}>
                  <BuscarCep>
                    <input
                      placeholder="Digite um CEP"
                      required
                      ref={inputBusca}
                      minLength="8"
                      maxLength="8"
                      pattern="[0-9]{8}"
                      value={cep.replace(/[^\d]/g, '')}
                      onChange={handleChangeCep}
                    />

                    <Button type="submit"> Buscar </Button>
                  </BuscarCep>
                </Col>

                <span
                  style={{
                    display: error == false ? 'none' : 'block',
                    transition: 'opacity 4s ease-in-out',
                  }}
                  className="cep_error"
                >
                  {errorMessage}
                </span>
              </StyledRow>
            </Form>
            <Form onSubmit={handleSubmitAddressAndShipping}>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="address">Endereço</Label>
                    <Input
                      id="address"
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={8}>
                  <FormGroup>
                    <Label for="complement">Complemento</Label>
                    <Input
                      id="complement"
                      type="text"
                      name="complement"
                      value={complement}
                      onChange={(e) => setComplement(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label for="number">Número</Label>
                    <Input
                      id="number"
                      type="text"
                      name="number"
                      value={number}
                      maxLength="10"
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="state">UF</Label>
                    <Input
                      id="state"
                      type="select"
                      name="state"
                      value={state}
                      disabled={myCep.localidade ? true : false}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <optgroup label="Estados">
                        {BRStates.map((brSt) => (
                          <option key={brSt.value} value={brSt.value}>
                            {brSt.label}
                          </option>
                        ))}
                      </optgroup>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="country">País</Label>
                    <Input
                      id="country"
                      type="select"
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                      disabled
                    >
                      <optgroup label="Países">
                        {Countries.map((cr) => (
                          <option key={cr.sigla2} value={cr.sigla2}>
                            {cr.nome}
                          </option>
                        ))}
                      </optgroup>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="city">Cidade</Label>
                    <Input
                      id="city"
                      type="text"
                      name="city"
                      value={city}
                      disabled={myCep.localidade ? true : false}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="district">Bairro</Label>
                    <Input
                      id="district"
                      type="text"
                      name="district"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="shipping_cost">Valor do Frete</Label>
                    <Input
                      id="shipping_cost"
                      type="text"
                      name="shipping_cost"
                      value={priceMask(shipping_cost + '')}
                      onChange={handleChangeShippingCost}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col
                  className="center footer__address--modal"
                  sm={{ size: 'auto', offset: 12 }}
                >
                  <Button color="danger" onClick={toggleModalAddAddress}>
                    Cancelar
                  </Button>{' '}
                  {!myCep.localidade ||
                  cep.replace(/[^\d]/g, '') !==
                    myCep?.cep.replace(/[^\d]/g, '') ? (
                    <SendButtonInfo
                      id="send_cep"
                      style={{
                        cursor:
                          !myCep.localidade ||
                          cep.replace(/[^\d]/g, '') !==
                            myCep?.cep.replace(/[^\d]/g, '')
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                    >
                      <UncontrolledTooltip placement="top" target="send_cep">
                        {!myCep.localidade
                          ? 'Digite um cep válido para liberar o envio dos dados .'
                          : cep.replace(/[^\d]/g, '') !==
                            myCep?.cep.replace(/[^\d]/g, '')
                          ? 'O cep deve ser o igual ao que foi pesquisado .'
                          : ''}
                      </UncontrolledTooltip>
                    </SendButtonInfo>
                  ) : (
                    ''
                  )}
                  <Button
                    style={{
                      cursor:
                        !myCep.localidade ||
                        cep.replace(/[^\d]/g, '') !==
                          myCep?.cep.replace(/[^\d]/g, '')
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                    disabled={
                      !myCep.localidade ||
                      cep.replace(/[^\d]/g, '') !==
                        myCep?.cep.replace(/[^\d]/g, '')
                        ? true
                        : false
                    }
                  >
                    Salvar endereço
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </ButtonDropdown>
    );
  } else {
    return (
      <ButtonDropdown
        isOpen={show}
        toggle={toggle}
        color={'link'}
        className="cartPanel"
        direction="up"
      >
        <DropdownToggle
          color={'link'}
          border="border-none"
          id="tooltipCartChatPainel"
          caret
        >
          {!show ? (
            <>
              <UncontrolledTooltip
                placement="bottom"
                target="tooltipCartChatPainel"
              >
                Carrinho
              </UncontrolledTooltip>
              <Icon
                size={20}
                icon="poli-icon pi-ecommerce-fill"
                position={'relative'}
                top={'2px'}
                right={'4px'}
              />
            </>
          ) : (
            <>
              <Icon
                size={20}
                icon="poli-icon pi-ecommerce-fill"
                position={'relative'}
                top={'2px'}
                right={'4px'}
              />
            </>
          )}
          <span
            style={{
              position: 'absolute',
              top: '2px',
              right: '-5px',
              background: 'var(--red-polichat)',
              borderRadius: '100%',
              fontSize: '0.7rem',
              fontWeight: 'bold',
              color: 'var(--primary-background-color)',
              alignItems: 'center',
              textAlign: 'center',
              padding: '1px',
              height: '20px',
              width: '20px',
              display: 'inline',
            }}
            title={`carrinho desabilitado`}
          >
            {`:'(`}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <h6 className="font-weight-bold text-left">
            <div
              className="mx-auto text-center mb-1"
              style={{
                borderRadius: '50%',
                background: 'white',
                width: 'fit-content',
              }}
            >
              <Icon
                size={45}
                color={'var(--activated-icons-color)'}
                icon={'poli-icon pi-poly-sad-fill	'}
              />
            </div>
          </h6>
          <p>
            É necessário que a empresa possua um catálogo e formas de pagamento
            configurados para utilizar esta funcionalidade.
          </p>

          {userData.superRole === 'adm' || userData.superRole === 'manager' ? (
            <p className="mx-auto text-left">
              <Link to="/shopping/payment-settings">Clique aqui</Link> para
              ativar uma nova configuração e começar a usar o PoliPay.
            </p>
          ) : (
            <p className="mx-auto text-left">
              Peça para seu gestor entrar em contato conosco!{' '}
              <Icon icon={'poli-icon pi-talks-line'} />
            </p>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  userData: state.chat?.config?.user,
  productsCatalog: state.chat?.catalog?.products,
  current_customer_id: state.general?.current_customer_id,
  agentSettings: state.chat.config?.general?.settings,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartPanel);
